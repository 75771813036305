import React from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import {LINKS} from "../../links";
import {RecalculationJobs} from "./RecalculationJobs";
import {NewRecalculationJob} from "./NewRecalculationJob";
import {ParameterAgeMetrics} from "./ParameterAgeMetrics";

export function Recalculation() {

    return (
        <Switch>
            <Route exact path={LINKS.recalculation()}>
                <div>
                    <ParameterAgeMetrics/>
                    <RecalculationJobs/>
                </div>
            </Route>
            <Route exact path={LINKS.newRecalculationJob()}>
                <NewRecalculationJob/>
            </Route>
            <Route path="">
                <Redirect to={LINKS.recalculation()}/>
            </Route>
        </Switch>
    );
}
