import {QuestionWithMetrics} from "../types/QuestionWithMetrics";
import {QuestionInfo} from "../types/QuestionInfo";
import {ParameterComparisonResults} from "../types/ParameterComparisonResults";
import {QuestionParameters} from "../types/QuestionParameters";
import {ParameterUpdateResults} from "../types/ParameterUpdateResults";
import {RecalculationJobStatuses} from "../types/RecalculationJobStatuses";
import {RecalculationJob} from "../types/RecalculationJob";
import {ParameterAgeMetrics} from "../types/ParameterAgeMetrics";
import {ParameterComparison} from "../types/ParameterComparison";
import {DataCollectionStudy} from "../types/DataCollectionStudy";

export function parseQuestionWithMetrics(responseData: any): QuestionWithMetrics {
    return {
        id: responseData.id,
        question: {
            id: responseData.question.id,
            name: responseData.question.name,
            status: responseData.question.status,
            pattern1: responseData.question.pattern_1,
            pattern2: responseData.question.pattern_2,
            pattern3: responseData.question.pattern_3,
            pattern4: responseData.question.pattern_4,
            pattern5: responseData.question.pattern_5,
            pattern6: responseData.question.pattern_6,
            pattern7: responseData.question.pattern_7,
            pattern8: responseData.question.pattern_8,
            optionA: responseData.question.option_a,
            optionB: responseData.question.option_b,
            optionC: responseData.question.option_c,
            optionD: responseData.question.option_d,
            optionE: responseData.question.option_e,
            optionF: responseData.question.option_f,
            correctOption: responseData.question.correct_option,
            locEap: responseData.question.loc_eap,
            locPsd: responseData.question.loc_psd,
            scaleEap: responseData.question.scale_eap,
            scalePsd: responseData.question.scale_psd,
            guessingEap: responseData.question.guessing_eap,
            guessingPsd: responseData.question.guessing_psd

        },
        numberOfObservations: responseData.number_of_observations,
        numberOfOptionAAnswers: responseData.number_of_option_a_answers,
        numberOfOptionBAnswers: responseData.number_of_option_b_answers,
        numberOfOptionCAnswers: responseData.number_of_option_c_answers,
        numberOfOptionDAnswers: responseData.number_of_option_d_answers,
        numberOfOptionEAnswers: responseData.number_of_option_e_answers,
        numberOfOptionFAnswers: responseData.number_of_option_f_answers,
        numberOfSkippedAnswers: responseData.number_of_skipped_answers
    }
}

export function parseQuestions(responseData: any[]): QuestionInfo[] {
    return responseData.map(questionInfo => ({
        id: questionInfo.id,
        name: questionInfo.name,
        status: questionInfo.status
    }));
}


export function parseQuestionParametersArray(responseData: any): QuestionParameters[] {
    return responseData.map(parseQuestionParameters);
}


function parseQuestionParameters(responseData: any): QuestionParameters {
    return {
        name: responseData.name,
        locEap: responseData.locEap,
        locPsd: responseData.locPsd,
        scaleEap: responseData.scaleEap,
        scalePsd: responseData.scalePsd,
        guessingEap: responseData.guessingEap,
        guessingPsd: responseData.guessingPsd,
        status: responseData.status,
        updatedAt: new Date(responseData.updatedAt)
    }
}

function parseParameterComparison(responseData: any): ParameterComparison {
    return {
        name: responseData.name,
        locEapCurrent: responseData.locEapCurrent,
        locPsdCurrent: responseData.locPsdCurrent,
        scaleEapCurrent: responseData.scaleEapCurrent,
        scalePsdCurrent: responseData.scalePsdCurrent,
        guessingEapCurrent: responseData.guessingEapCurrent,
        guessingPsdCurrent: responseData.guessingPsdCurrent,
        currentStatus: responseData.currentStatus,
        locEapNew: responseData.locEapNew,
        locPsdNew: responseData.locPsdNew,
        scaleEapNew: responseData.scaleEapNew,
        scalePsdNew: responseData.scalePsdNew,
        guessingEapNew: responseData.guessingEapNew,
        guessingPsdNew: responseData.guessingPsdNew,
        newStatus: responseData.newStatus,
        tooBigLocChange: parseBooleanValue(responseData.tooBigLocChange),
        tooBigScaleChange: parseBooleanValue(responseData.tooBigScaleChange),
        tooHighPosteriorCertainty: parseBooleanValue(responseData.tooHighPosteriorCertainty),
        strangeStatusChange: parseBooleanValue(responseData.strangeStatusChange),
        willBeUpdated: parseBooleanValue(responseData.willBeUpdated),
    }
}

export function parseParameterComparisonResults(responseData: any): ParameterComparisonResults {
    return {
        parametersWithWarnings: responseData.parametersWithWarnings,
        parameterComparisons: responseData.parameterComparisons.map((p: any) => parseParameterComparison(p)),
    }
}

export function parseParameterUpdateResults(responseData: any): ParameterUpdateResults {
    return {
        parameters: responseData.parameters.map((p: any) => parseQuestionParameters(p))
    }
}

export function parseRecalculationJob(responseData: any): RecalculationJob {
    return {
        createdAt: new Date(responseData.createdAt),
        createdBy: responseData.createdBy,
        recalculationJobId: responseData.recalculationJobId,
        targetBefore: new Date(responseData.targetBefore),
        targetAfter: new Date(responseData.targetAfter),
        targetRecalculatedBefore: new Date(responseData.targetRecalculatedBefore),
        status: responseData.status,
        theoreticalNumberOfTestsToStart: responseData.theoreticalNumberOfTestsToStart,
        actualNumberOfTestsToStart: responseData.actualNumberOfTestsToStart,
        numberOfQueuedTests: responseData.numberOfQueuedTests
    }
}

export function parseRecalculationJobStatuses(responseData: any): RecalculationJobStatuses {
    return {
        activeJobCompletedTestCount: responseData.activeJobCompletedTestCount,
        jobs: responseData.jobs.map((j: any) => parseRecalculationJob(j))
    }
}


export function parseParameterAgeMetrics(responseData: any): ParameterAgeMetrics[] {
    return responseData.map((metrics: any) => {
        return {
            period: metrics.period,
            youngerThanOneMonth: metrics.youngerThanOneMonth,
            youngerThanThreeMonths: metrics.youngerThanThreeMonths,
            youngerThanSixMonths: metrics.youngerThanSixMonths,
            youngerThanTwelveMonths: metrics.youngerThanTwelveMonths,
            olderThanTwelveMonths: metrics.olderThanTwelveMonths
        };
    });
}

export function parseDataCollectionStudy(responseData: any): DataCollectionStudy {
    return {
        studyId: responseData.study_id,
        dataCollectionEnabled: responseData.data_collection_enabled
    }
}

export function parseDataCollectionStudies(responseData: any[]): DataCollectionStudy[] {
    return responseData.map(dataCollectionStudy => (
        parseDataCollectionStudy(dataCollectionStudy)
    ));
}

export function formatError(error: any): string {
    const defaultErrorMessage = 'Something went wrong';
    if (!error) {
        return defaultErrorMessage;
    }

    const {code, message} = error;
    if (message) {
        return message;
    }

    if (code) {
        return code;
    }

    return defaultErrorMessage;
}

function parseBooleanValue(value: any): boolean {
    return Boolean(value);
}
