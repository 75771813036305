import {Fragment, useCallback, useState} from 'react';
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import {useCompareParameters} from "../../../../../../api/useCompareParameters";
import {ParameterComparisonTable} from "./components/ParameterComparisonTable";
import {useUpdateParameters} from "../../../../../../api/useUpdateParameters";
import {DisplayParameters} from "./components/ParameterUpdateResults";
import {useAvailableTableIds} from "../../../../../../api/useAvailableTableIds";
import {CircularProgress, InputLabel} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import styled from "styled-components";
import {RequiredColumns} from "./components/RequiredColumns";
import {BigQueryDatasetInfo} from "./BigQueryDatasetInfo";

export function ParameterUpdate() {
    const {tableIds, loading: loadingTableIds, error: tableIdsError} = useAvailableTableIds();
    const [selectedTableId, setSelectedTableId] = useState<string>('');

    const [doCompareParameters, {
        parameterComparisonResults,
        loading: comparingParameters,
        error: comparisonError
    }] = useCompareParameters(selectedTableId);
    const [updateParameters, {
        results: parameterUpdateResults,
        loading: loadingUpdateParams,
        error: errorUpdateParams
    }] = useUpdateParameters();

    const handleSelect = useCallback((e: any) => {
        const {value} = e.target || {};
        setSelectedTableId(value || '');
    }, []);

    const handleChange = useCallback((tableName: string) => {
        updateParameters(tableName);
    }, [updateParameters]);

    const compareParameters = useCallback(() => {
        doCompareParameters().then(() => {
            console.log('Successfully compared parameters');
        });
    }, [doCompareParameters]);

    const isLoadingParameterResults = comparingParameters || loadingUpdateParams;

    return (
        <div>
            <h2>Compare existing parameters to new parameters from BigQuery</h2>
            <InfoWrapper>
                <BigQueryDatasetInfo/>
                <RequiredColumns/>
                <br/>
                {tableIdsError && <h3>Failed to load BigQuery tables: {tableIdsError}. Try refreshing the page.</h3>}
                {!parameterUpdateResults && !tableIdsError && (
                    <FlexBox>
                        {(!loadingTableIds && tableIds.length === 0) && (
                            <p>No tables in BigQuery dataset. Add a table there and try again.</p>
                        )}
                        {(!loadingTableIds && tableIds.length > 0) && (
                            <Fragment>
                                <SelectWrapper>
                                    <Label id="select-label">Select BigQuery table</Label>
                                    <Select
                                        required
                                        value={!!selectedTableId ? selectedTableId : 'Select ...'}
                                        onChange={handleSelect}
                                        disabled={loadingTableIds}
                                        inputProps={{
                                            name: 'selectTableId',
                                            id: 'select-table-id'
                                        }}
                                        autoWidth={true}
                                    >
                                        <MenuItem value={'Select ...'} style={{display: 'none'}}>Select ...</MenuItem>
                                        {tableIds.map(tableId => (
                                            <MenuItem key={`option-${tableId}`} value={tableId}>{tableId}</MenuItem>
                                        ))}
                                    </Select>
                                </SelectWrapper>
                                <Button
                                    color={'primary'}
                                    variant={'contained'}
                                    disabled={isLoadingParameterResults}
                                    onClick={compareParameters}
                                    style={{maxHeight: 40, width: 108}}
                                >
                                    {isLoadingParameterResults ? <CircularProgress size={23.5}/> : 'Compare'}
                                </Button>
                            </Fragment>
                        )}
                    </FlexBox>
                )}
            </InfoWrapper>
            {(comparisonError || errorUpdateParams) && <h3>{comparisonError} {errorUpdateParams}</h3>}
            {!isLoadingParameterResults && !parameterUpdateResults && parameterComparisonResults && (
                <ParameterComparisonTable
                    results={parameterComparisonResults}
                    updateParameters={handleChange.bind(null, selectedTableId)}
                />
            )}
            {!isLoadingParameterResults && parameterUpdateResults && (
                <DisplayParameters parameters={parameterUpdateResults.parameters}/>
            )}
        </div>
    )
}

const InfoWrapper = styled.div`
  width: 400px;
  margin: 0 auto;
`;

const FlexBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
`;

const SelectWrapper = styled(FlexBox)`
  flex-direction: column;
  align-items: flex-start;
  margin-right: 32px;
  flex: 1;
  margin-bottom: 0;
`;

const Label = styled(InputLabel)`
  margin-bottom: 12px;
`;
