import {useCallback, useMemo, useState} from 'react';
import styled from 'styled-components';
import DateFnsUtils from '@date-io/date-fns';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import {StartRecalculationJob} from "./components/StartRecalculationJob";
import queryString from "query-string";
import {useLocation} from "react-router-dom";

export function NewRecalculationJob() {
    const [fromDate, setFromDate] = useState(new Date(2020, 0, 1));
    const [toDate, setToDate] = useState(new Date());
    const [recalculatedBeforeDate, setrecalculatedBeforeDate] = useState(new Date());

    const handleFromDateChange = useCallback((date: any) => {
        setFromDate(date);
    }, []);
    const handleToDateChange = useCallback((date: any) => {
        setToDate(date);
    }, []);
    const handlerecalculatedBeforeDateChange = useCallback((date: any) => {
        setrecalculatedBeforeDate(date);
    }, []);

    const location = useLocation();
    const hasActiveJob = useMemo(() => {
        const queryParams = queryString.parse(location.search);
        return queryParams.hasActiveJob === 'true';
    }, [location.search]);

    return (
        <div>
            <Header>
                <h1>Create a new recalculation job!</h1>
                {hasActiveJob && (
                    <Warning>
                        Note: A recalculation job is currently running, and you won't be able to create a new one until it's completed.
                    </Warning>
                )}
            </Header>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <div>
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="yyyy-MM-dd"
                        margin="normal"
                        id="date-picker-from"
                        label="From"
                        value={fromDate}
                        onChange={handleFromDateChange}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        maxDate={toDate ? toDate : undefined}
                    />

                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="yyyy-MM-dd"
                        margin="normal"
                        id="date-picker-to"
                        label="To"
                        value={toDate}
                        onChange={handleToDateChange}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        minDate={fromDate ? fromDate : undefined}
                        maxDate={new Date()}
                    />
                </div>
            </MuiPickersUtilsProvider>
             <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="yyyy-MM-dd hh:mm:ss"
                        margin="normal"
                        id="date-picker-ignore-if-recalculated-after"
                        label="Ignore if recalculated after"
                        value={recalculatedBeforeDate}
                        onChange={handlerecalculatedBeforeDateChange}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        minDate={fromDate ? fromDate : undefined}
                    />
            </MuiPickersUtilsProvider>
            <StartJobWrapper>
                <StartRecalculationJob
                    fromDate={fromDate}
                    toDate={toDate}
                    recalculatedBeforeDate={recalculatedBeforeDate}
                    isDisabled={hasActiveJob}
                />
            </StartJobWrapper>
        </div>
    );
}

const StartJobWrapper = styled.div`
    max-width: 480px;
    margin: 20px auto;
`;

const Header = styled.div`
  max-width: 480px;
  margin: 0 auto;
`;

const Warning = styled.p`
    color: red;
`;
