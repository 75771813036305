import {useCallback, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {buildRequestHeaders, clearToken, getToken} from "../services/auth/helpers";
import {parseUrl} from "../config";
import {formatError} from "./utils";

interface ApiCallReturn {
    data?: any;
    loading: boolean;
    error: string | null;
    refetch: () => void
}

export function useApiCall(url: string, body: any = undefined): ApiCallReturn {
    const [status, setStatus] = useState({loading: true, error: null, data: null});
    const history = useHistory();
    const authorizationToken = getToken();

    const makeApiCall = useCallback(() => {
        if (!authorizationToken) {
            return;
        }
        setStatus({loading: true, error: null, data: null});
        const options = {
            method : body ? 'POST': 'GET',
            headers: buildRequestHeaders(),
            body: body
        };

        fetch(parseUrl(url), options).then(res => res.json()).then(response => {
            if (response.status === 403) {
                clearToken();
                history.push('/login');
            }
            else if (response.data) {
                setStatus({loading: false, error: null, data: response.data});
            }
            else {
                throw new Error(formatError(response.error));
            }
        }).catch(error => {
            console.log(error);
            setStatus({loading: false, error: error.toString(), data: null});
        });
    }, [url, authorizationToken, history, body]);

    const refetch = useCallback(() => {
        makeApiCall();
    }, [makeApiCall]);

    useEffect(() => {
        makeApiCall();
    }, [makeApiCall]);

    return {
        loading: status.loading,
        error: status.error,
        data: status.data,
        refetch
    }
}
