import {Link, Redirect, useLocation} from 'react-router-dom';
import styled from "styled-components";
import {getToken} from "../../../../services/auth/helpers";
import {Spinner} from "../../../../components/Spinner";
import {useQuestions} from "../../../../api/useQuestions";


export function Questions() {
    const {questions, loading, error} = useQuestions();
    const authorizationToken = getToken();
    const location = useLocation()

    if (!authorizationToken) {
        return <Redirect to={'/login'}/>;
    }

    if (loading) {
        return <Spinner/>;
    }

    if (error) {
        return (
            <p>
                {error}
            </p>
        );
    }
    const getQuestionNumber = (val: string) : number => {
        const result = val.match(/\d+/);
        return result ? parseInt(result[0], 10) : 0;
    }

    return (
        <Wrapper>
            <Main>
                <TableWrapper>
                    <thead>
                    <tr>
                        <Header>Question id</Header>
                        <Header>Question name</Header>
                        <Header>Question status</Header>
                    </tr>
                    </thead>
                    <tbody>
                    {questions.sort((a, b) => getQuestionNumber(a.name) - getQuestionNumber(b.name)).map((question: { id: string; name: string; status: string }) =>
                        <tr key={question.id}>
                            <Field>
                                <Link to={`${location.pathname}/${question.id}`}>
                                    {question.id}
                                </Link>
                            </Field>
                            <Field>{question.name}</Field>
                            <Field>{question.status}</Field>
                        </tr>
                    )}
                    </tbody>
                </TableWrapper>

            </Main>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    display: flex;
`;

const Main = styled.div`
    flex: 1;
`;


const TableWrapper = styled.table`
  padding: 20px;
  margin: auto;
`;

const Header = styled.th`
  padding: 10px;
  border: solid 1px;
  background-color: burlywood;
`;

const Field = styled.td`
  padding: 10px;
  border: solid 1px;
`;

