const LocalConfig = {
    GOOGLE_CLIENT_ID: '320084368669-u8bcio4lkgeo4d4dgu2t8d0nvb833iab.apps.googleusercontent.com',
    BASE_URL: 'http://localhost:3002',
    ALVA_APP_BASE_URL: 'http://localhost:3000'
};

const ProductionConfig = {
    GOOGLE_CLIENT_ID: '37625479344-n0m55f01f44rj0d9957hv1iojea5opnp.apps.googleusercontent.com',
    BASE_URL: 'https://logic-test-management.alvalabs.io',
    ALVA_APP_BASE_URL: 'https://app.alvalabs.io'
};

const StagingConfig = {
    GOOGLE_CLIENT_ID: '320084368669-u8bcio4lkgeo4d4dgu2t8d0nvb833iab.apps.googleusercontent.com',
    BASE_URL: 'https://logic-test-management-staging.alvalabs.io',
    ALVA_APP_BASE_URL: 'https://staging.app.alvalabs.io'
};

function isLocal() {
    return window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
}

function isStaging() {
    return window.location.hostname.includes('logic-test-management-staging.alvalabs.io');
}

export function getConfig() {
    if (isLocal()) {
        return LocalConfig;
    }
    if (isStaging()) {
        return StagingConfig;
    }

    return ProductionConfig;
}

export function parseUrl(endpoint: string): string {
    // safari doesn't support regex lookbehinds, therefore not using those
    return `${endpoint}`.replace(/([^:]\/)\/+/g, "$1");
}
