import {useCallback, useMemo} from "react";
import {useApiOperation} from "./useApiOperation";


type UseAddDataCollectionStudyParticipantsReturn = [(studyId: string, externalIds: string[]) => void, {
    results?: string[],
    loading: boolean;
    error?: string | null;
}]

export function useAddDataCollectionStudyParticipants(): UseAddDataCollectionStudyParticipantsReturn {
    const url = '/api/dataCollection.addStudyParticipants';
    const [request, {error, loading, data}] = useApiOperation(url);

    const addDataCollectionStudyParticipants = useCallback((studyId: string, externalIds: string[]) => {
        const payload = {'study_id': studyId, 'external_ids': externalIds};
        return request(payload);
    }, [request]);

    const results = useMemo(() => {
        return data;
    }, [data]);

    return [addDataCollectionStudyParticipants, {
        results,
        loading,
        error
    }]
}
