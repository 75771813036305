import {useMemo} from "react";
import {ParameterAgeMetrics} from "../types/ParameterAgeMetrics";
import {parseParameterAgeMetrics} from "./utils";
import {useApiCall} from "./useApiCall";

interface UseParameterAgeMetricsReturn {
    parameterAgeMetrics: ParameterAgeMetrics[];
    loading: boolean;
    error: string | null;
    refetch: () => void;
}

export function useParameterAgeMetrics(): UseParameterAgeMetricsReturn {
    const url = '/api/parameterAgeMetrics.list';
    const {loading, error, refetch, data} = useApiCall(url);

    const parameterAgeMetrics = useMemo(() => {
        return data ? parseParameterAgeMetrics(data) : [];
    }, [data]);

    return {
        parameterAgeMetrics,
        loading,
        error,
        refetch
    }
}
