import {useMemo} from "react";
import {parseDataCollectionStudies} from "./utils";
import {useApiCall} from "./useApiCall";
import {DataCollectionStudy} from "../types/DataCollectionStudy";

interface UseDataCollectionStudiesReturn {
    dataCollectionStudies: DataCollectionStudy[];
    loading: boolean;
    error: string | null;
    refetch: () => void;
}

export function useDataCollectionStudies(): UseDataCollectionStudiesReturn {
    const url = '/api/dataCollection.listStudies';
    const {data, error, loading, refetch} = useApiCall(url);
    const dataCollectionStudies = useMemo(() => {
        if (data) {
            return parseDataCollectionStudies(data.dataCollectionStudies);
        }
        return [];
    }, [data]);

    return {
        dataCollectionStudies,
        loading,
        error,
        refetch
    }
}
