import {useMemo} from "react";
import {QuestionParameters} from "../types/QuestionParameters";
import {parseQuestionParametersArray} from "./utils";
import {useApiCall} from "./useApiCall";

interface UseCurrentParametersReturn {
    parameters: QuestionParameters[];
    loading: boolean;
    error: string | null;
    refetch: () => void;
}

export function useCurrentParameters(): UseCurrentParametersReturn {
    const url = '/api/parameters.list';
    const {loading, error, refetch, data} = useApiCall(url);

    const parameters = useMemo(() => {
        return data ? parseQuestionParametersArray(data) : [];
    }, [data]);

    return {
        parameters,
        loading,
        error,
        refetch
    }
}
