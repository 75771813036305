import {useCallback} from 'react';
import {Question} from "../../../../../../../../types/Question";
import {useChangeStatusOfQuestion} from "../../../../../../../../api/useChangeStatusOfQuestion";
import {Spinner} from "../../../../../../../../components/Spinner";

interface QuestionStatusProps {
    question?: Question;
    onStatusChanged: () => void;
}

const OPTIONS = [
    'DRAFT',
    'CANDIDATE',
    'ACTIVE',
    'RETIRED'
];

export function QuestionStatus({question, onStatusChanged}: QuestionStatusProps) {
    const [changeStatus, {error, loading}] = useChangeStatusOfQuestion(question?.id, onStatusChanged);
    const handleChange = useCallback((event: any) => {
        changeStatus(event.target.value);
    }, [changeStatus]);
    if (!question) {
        return null;
    }

    if (error) {
        return (
            <div>
                Failed to update status, please refresh the page: {error}
            </div>
        )
    }

    if (loading) {
        return <Spinner/>;
    }
    return (
        <select value={question.status} onChange={handleChange}>
            {OPTIONS.map(option => (
                <option key={`option-${option}`} value={option}>{option}</option>
            ))}
        </select>

    );
}

