import {useCallback, useState} from "react";
import {useHistory} from "react-router-dom";
import {buildRequestHeaders, clearToken} from "../services/auth/helpers";
import {parseUrl} from "../config";
import {formatError} from "./utils";

interface ApiOperationStatus {
    data?: any;
    loading: boolean;
    error: string | null;
}

interface ApiOperationOptions {
    onCompleted?: () => void,
    onError?: () => void
}

type OperationReturn = [(data: any | null) => Promise<void>, ApiOperationStatus];


export function useApiOperation(url: string, options: ApiOperationOptions = {}): OperationReturn {
    const [status, setStatus] = useState({loading: false, error: null, data: null});
    const history = useHistory();

    const makeApiCall = useCallback((data: any) => {
        setStatus({loading: true, error: null, data: null});
        const fetchOptions = {
            method: 'POST',
            headers: buildRequestHeaders(),
            body: data ? JSON.stringify(data) : null
        };
        return fetch(parseUrl(url), fetchOptions).then(res => res.json()).then(response => {
            if (response.status === 403) {
                clearToken();
                history.push('/login');
            }
            else if (response.data) {
                setStatus({loading: false, error: null, data: response.data});
                if (options && options?.onCompleted) {
                    options.onCompleted();
                }
            }
            else {
                throw new Error(formatError(response.error));
            }
        }).catch(error => {
            console.log(error);
            setStatus({loading: false, error: error.toString(), data: null});
            if (options && options?.onError) {
                options.onError();
            }
        });
    }, [url, history, options]);

    return [makeApiCall, {
        loading: status.loading,
        error: status.error,
        data: status.data,
    } as ApiOperationStatus]
}
