import styled from "styled-components";

export function RequiredColumns() {
    return (
        <Wrapper>
            <h3>Required columns in BigQuery table:</h3>
            <List>
                <ListItem><code>name</code></ListItem>
                <ListItem><code>loc_eap</code></ListItem>
                <ListItem><code>loc_psd</code></ListItem>
                <ListItem><code>scale_eap</code></ListItem>
                <ListItem><code>scale_psd</code></ListItem>
                <ListItem><code>guessing_eap</code></ListItem>
                <ListItem><code>guessing_psd</code></ListItem>
                <ListItem><code>status</code></ListItem>
            </List>
        </Wrapper>
    );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 auto;
`;

const List = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const ListItem = styled.li`
  text-align: left;
  margin-bottom: 4px;
`;
