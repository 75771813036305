import {useMemo} from "react";
import {parseDataCollectionStudy} from "./utils";
import {useApiCall} from "./useApiCall";
import {DataCollectionStudy} from "../types/DataCollectionStudy";

interface UseDataCollectionStudyReturn {
    dataCollectionStudy?: DataCollectionStudy | null;
    loading: boolean;
    error: string | null;
    refetch: () => void;
}

export function useDataCollectionStudy(studyId: string): UseDataCollectionStudyReturn {
    const url = '/api/dataCollection.getStudy';
    const {data, error, loading, refetch} = useApiCall(url, JSON.stringify({'study_id': studyId}));
    const dataCollectionStudy = useMemo(() => {
        if (data) {
            return parseDataCollectionStudy(data);
        }
        return null;
    }, [data]);

    return {
        dataCollectionStudy,
        loading,
        error,
        refetch
    }
}
