import {useApiOperation} from "./useApiOperation";
import {useCallback} from "react";

interface UpdateActiveRecalculationJobStatus {
    loading: boolean;
    error: string | null;
}

type UseUpdateActiveRecalculationJobStatusReturn = [() => Promise<void>, UpdateActiveRecalculationJobStatus];

export function useUpdateActiveRecalculationJobStatus(): UseUpdateActiveRecalculationJobStatusReturn {
    const url = '/api/recalculationJobs.updateActiveJob';
    // eslint-disable-next-line
    const [request, {data, error, loading}] = useApiOperation(url);

    const makeRequest = useCallback(() => {
        return request({});
    }, [request]);

    return [makeRequest, {
        loading,
        error
    } as UpdateActiveRecalculationJobStatus]
}
