import {useParameterAgeMetrics} from "../../../../../api/useParameterAgeMetrics";
import {useMemo} from "react";

interface ReturnType {
    xAxisValues: {label: string}[];
    youngerThanOneMonth: {value: number | null}[];
    youngerThanThreeMonths: {value: number | null}[];
    youngerThanSixMonths: {value: number | null}[];
    youngerThanTwelveMonths: {value: number | null}[];
    olderThanTwelveMonths: {value: number | null}[];
}

export function useParameterAgeMetricsLogic(): ReturnType {
    const {parameterAgeMetrics} = useParameterAgeMetrics();

    const xAxisValues = useMemo(() => {
        if (!parameterAgeMetrics) {
            return [];
        }

        return parameterAgeMetrics.map(item => {
            return {
                label: item.period
            };
        });
    }, [parameterAgeMetrics]);

    const youngerThanOneMonth = useMemo(() => {
        if (!parameterAgeMetrics) {
            return [];
        }

        return parameterAgeMetrics.map(item => {
            return {
                value: item.youngerThanOneMonth || null
            };
        });
    }, [parameterAgeMetrics]);

    const youngerThanThreeMonths = useMemo(() => {
        if (!parameterAgeMetrics) {
            return [];
        }

        return parameterAgeMetrics.map(item => {
            return {
                value: item.youngerThanThreeMonths || null
            };
        });
    }, [parameterAgeMetrics]);

    const youngerThanSixMonths = useMemo(() => {
        if (!parameterAgeMetrics) {
            return [];
        }

        return parameterAgeMetrics.map(item => {
            return {
                value: item.youngerThanSixMonths || null
            };
        });
    }, [parameterAgeMetrics]);

    const youngerThanTwelveMonths = useMemo(() => {
        if (!parameterAgeMetrics) {
            return [];
        }

        return parameterAgeMetrics.map(item => {
            return {
                value: item.youngerThanTwelveMonths || null
            };
        });
    }, [parameterAgeMetrics]);

    const olderThanTwelveMonths = useMemo(() => {
        if (!parameterAgeMetrics) {
            return [];
        }

        return parameterAgeMetrics.map(item => {
            return {
                value: item.olderThanTwelveMonths || null
            };
        });
    }, [parameterAgeMetrics]);

    return {
        xAxisValues,
        youngerThanOneMonth,
        youngerThanThreeMonths,
        youngerThanSixMonths,
        youngerThanTwelveMonths,
        olderThanTwelveMonths
    };
}
