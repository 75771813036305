import React from 'react'
import {useDropzone} from 'react-dropzone'
import styled from "styled-components";

interface DropzoneProps {
    onDrop: (acceptedFiles: File[]) => void;
}

export function Dropzone({onDrop}: DropzoneProps) {
    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop, accept: {'image/svg+xml': []}})

    return (
        <Wrapper {...getRootProps()}>
            <input {...getInputProps()}/>
            {
                isDragActive ?
                    <p>Drop the files here ...</p> :
                    <div>
                        <p>Drag and drop some files here, or click to select files</p>
                        <em>(Only *.svg files will be accepted)</em>
                    </div>
            }
        </Wrapper>
    )
}

const Wrapper = styled.div`
  border: 2px solid black;
  padding: 16px;
  border-radius: 4px;
  display: flex;
  min-height: 150px;
  max-width: 400px;
  margin: auto;
  display: flex;
  & > :last-child {
    margin: auto;
  }
`;
