import {useCallback} from 'react';
import {DataGrid} from '@material-ui/data-grid';
import {QuestionParameters} from "../../../../../../../../types/QuestionParameters";
import {
    getDefaultSortingForParametersTable,
    nameSortComparator,
    parameterValueFormatter
} from "../../../../utils/tableUtils";
import {getCommonColumnProperties} from "../../../../../../utils/tableUtils";
import {v4 as uuid} from "uuid";

interface DisplayParametersProps {
    parameters: QuestionParameters[];
}

const columns = [
    {field: 'name', sortComparator: nameSortComparator},
    {field: 'status'},
    {field: 'locEap', valueFormatter: parameterValueFormatter},
    {field: 'locPsd', valueFormatter: parameterValueFormatter},
    {field: 'scaleEap', valueFormatter: parameterValueFormatter},
    {field: 'scalePsd', valueFormatter: parameterValueFormatter},
    {field: 'guessingEap', valueFormatter: parameterValueFormatter},
    {field: 'guessingPsd', valueFormatter: parameterValueFormatter}
].map(cell => ({...getCommonColumnProperties({flex: 1}), ...cell}));

export function DisplayParameters({parameters}: DisplayParametersProps) {
    const getRows = useCallback((params: QuestionParameters[]) => {
        return params.map((p: QuestionParameters) => {
            return Object.assign({'id': uuid()}, p)
        });
    }, []);

    return (
        <div>
            <h2>Parameters that have been uploaded</h2>
            <DataGrid
                rows={getRows(parameters)}
                columns={columns}
                pageSize={20}
                autoHeight={true}
                sortModel={getDefaultSortingForParametersTable()}
                disableSelectionOnClick={true}
            />
        </div>
    )
}
