import {useCallback} from "react";
import {useApiOperation} from "./useApiOperation";


type UseChangeStatusOfQuestionReturn = [(newStatus: string) => void, {
    loading: boolean;
    error?: string | null;
}]

export function useChangeStatusOfQuestion(questionId: string | undefined, onCompleted: () => void): UseChangeStatusOfQuestionReturn {
    const url = `/api/questions.changeStatus/${questionId}`;
    const [request, {error, loading}] = useApiOperation(url, {onCompleted});

    const changeStatus = useCallback((newStatus: string) => {
        const payload = {status: newStatus};
        return request(payload);
    }, [request]);

    return [changeStatus, {
        error,
        loading
    }];
}
