import {useCallback} from "react";
import {useApiOperation} from "./useApiOperation";
import {Item} from "../types/Item";


type UseUploadQuestionsReturn = [(questions: { [id: string]: Item }) => void, {
    loading: boolean;
    error?: string | null;
}]

export function useUploadQuestions(onCompleted: () => void): UseUploadQuestionsReturn {
    const url = `/api/questions.upload`;
    const [request, {error, loading}] = useApiOperation(url, {onCompleted});

    const uploadQuestions = useCallback((questions: { [id: string]: Item }) => {
        const payload = {
            questions: Object.keys(questions).map((name) => {
                const { name: _, ...rest } = questions[name as string];
                return { name, ...rest };
            }),
        };
        return request(payload);
    }, [request]);

    return [uploadQuestions, {
        error,
        loading
    }];
}
