import {useCallback, useMemo} from "react";
import {ParameterUpdateResults} from "../types/ParameterUpdateResults";
import {parseParameterUpdateResults} from "./utils";
import {useApiOperation} from "./useApiOperation";

type UseUpdateParametersReturn = [(tableName: string) => void, {
    results?: ParameterUpdateResults | null,
    loading: boolean;
    error?: string | null;
}]

export function useUpdateParameters(): UseUpdateParametersReturn {
    const url = '/api/parameters.update';
    const [request, {error, loading, data}] = useApiOperation(url);

    const updateParameters = useCallback((tableName: string) => {
        const payload = {
            tableName
        };
        return request(payload);
    }, [request]);

    const results = useMemo(() => {
        return data ? parseParameterUpdateResults(data) : null;
    }, [data]);

    return [updateParameters, {
        results,
        loading,
        error
    }]
}
