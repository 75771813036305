import {Redirect, Route, Switch, useParams} from "react-router-dom";
import styled from "styled-components";
import {Questions} from "./pages/Questions";
import {Question} from "./pages/Questions/components/Question";
import {Parameters} from "./pages/Parameters";
import {Recalculation} from "./pages/Recalculation";
import {Navbar} from "./Navbar";
import {LINKS} from "./links";
import {DataCollection} from "./pages/DataCollection";
import {DataCollectionStudy} from "./pages/DataCollectionStudy";
import {UploadItems} from "./pages/UploadItems";


function RenderQuestion() {
    const params = useParams();

    // @ts-ignore
    return <Question questionId={params.questionId}/>;
}

export const Home = () => (
    <Wrapper>
        <Navbar/>
        <Content>
            <Switch>
                <Route path={LINKS.parameters()}>
                    <Parameters/>
                </Route>
                <Route exact path={LINKS.questions()}>
                    <Questions/>
                </Route>
                <Route path={LINKS.recalculation()}>
                    <Recalculation/>
                </Route>
                <Route exact path={LINKS.question(':questionId')}>
                    <RenderQuestion/>
                </Route>
                <Route exact path={LINKS.dataCollection(':studyId')}>
                    <DataCollectionStudy/>
                </Route>
                <Route path={LINKS.dataCollection()}>
                    <DataCollection/>
                </Route>
                <Route path={LINKS.uploadItems()}>
                    <UploadItems/>
                </Route>
                <Route path="">
                    <Redirect to={LINKS.questions()}/>
                </Route>
            </Switch>
        </Content>
    </Wrapper>
);

const Wrapper = styled.div`
  position: relative;
`;

const Content = styled.div`
  position: absolute;
  top: 80px;
  height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
  width: 100%;
`;
