import {useCallback, useMemo} from "react";
import {useApiOperation} from "./useApiOperation";
import {RecalculationJobPreview} from "../types/RecalculationJobPreview";


interface PreviewRecalculationJobStatus {
    jobPreview: RecalculationJobPreview | null;
    loading: boolean;
    error: string | null;
}

type PreviewRecalculationJobReturn = [(after: string | Date, before: string | Date, recalculatedBefore: string | Date) => Promise<void>, PreviewRecalculationJobStatus];

function parsePreviewRecalculationJob(data: any) {
    return {
        totalTests: data.totalTests
    }
}


export function usePreviewRecalculationJob(): PreviewRecalculationJobReturn {
    const url = '/api/recalculationJobs.preview';
    const [request, {data, error, loading}] = useApiOperation(url);

    const jobPreview = useMemo(() => {
        if (data) {
            return parsePreviewRecalculationJob(data);
        }
        return null;
    }, [data]);

    const makeRequest = useCallback((after: string | Date, before: string | Date, recalculatedBefore: string | Date) => {
        const payload = {
            after,
            before,
            recalculatedBefore
        };
        return request(payload);
    }, [request]);

    return [makeRequest, {
        jobPreview,
        loading,
        error
    } as PreviewRecalculationJobStatus]
}
