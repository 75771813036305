import styled from "styled-components";
import {Question} from "../../../../../../../../types/Question";

interface ParametersProps {
    question?: Question
}

export function Parameters({question}: ParametersProps) {

    if (!question) {
        return null;
    }

    const parameters = [
        {name: 'locEap', value: question.locEap},
        {name: 'locPsd', value: question.locPsd},
        {name: 'scaleEap', value: question.scaleEap},
        {name: 'scalePsd', value: question.scalePsd},
        {name: 'guessingEap', value: question.guessingEap},
        {name: 'guessingPsd', value: question.guessingPsd}
    ];

    return (
        <TableWrapper>
            <thead>
            <tr>
                <Header>Parameter</Header>
                <Header>Value</Header>
            </tr>
            </thead>
            <tbody>
            {parameters && parameters.map((parameter: { name: string; value: number }) =>
                <tr key={parameter.name}>
                    <Field>
                        {parameter.name}
                    </Field>
                    <Field>
                        {parameter.value}
                    </Field>
                </tr>
            )}
            </tbody>
        </TableWrapper>
    )
}

const Header = styled.th`
  padding: 10px;
  border: solid 1px;
  background-color: burlywood;
`;

const Field = styled.td`
  padding: 10px;
  border: solid 1px;
`;

const TableWrapper = styled.table`
  margin: auto;
  padding: 20px;
`;
