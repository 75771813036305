import React from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";
import {Link} from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import {LINKS} from "./links";


export function Navbar() {

    return (
        <AppBar>
            <Toolbar>
                <InnerWrapper>
                    <div>
                        <Button color={'inherit'}>
                            <NavLink to={LINKS.questions()}>
                                Questions
                            </NavLink>
                        </Button>
                        <Button color={'inherit'}>
                            <NavLink to={LINKS.parameters()}>
                                Parameters
                            </NavLink>
                        </Button>
                        <Button color={'inherit'}>
                            <NavLink to={LINKS.recalculation()}>
                                Recalculation
                            </NavLink>
                        </Button>
                        <Button color={'inherit'}>
                            <NavLink to={LINKS.dataCollection()}>
                                Data collection
                            </NavLink>
                        </Button>
                        <Button color={'inherit'}>
                            <NavLink to={LINKS.uploadItems()}>
                                Upload items
                            </NavLink>
                        </Button>
                    </div>
                    <div>
                        <Button href={LINKS.logout()} color={'inherit'}>
                            Logout
                        </Button>
                    </div>
                </InnerWrapper>
            </Toolbar>
        </AppBar>
    );
}

Navbar.propTypes = {
    items: PropTypes.array
};

const InnerWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;

const NavLink = styled(Link)`
    text-decoration: none;
    color: unset;
`;
