import {useCallback, useMemo} from "react";
import {parseDataCollectionStudy} from "./utils";
import {useApiOperation} from "./useApiOperation";
import {DataCollectionStudy} from "../types/DataCollectionStudy";


type UseCreateDataCollectionStudyReturn = [() => void, {
    results?: DataCollectionStudy | null,
    loading: boolean;
    error?: string | null;
}]

export function useCreateDataCollectionStudy(): UseCreateDataCollectionStudyReturn {
    const url = '/api/dataCollection.createStudy';
    const [request, {error, loading, data}] = useApiOperation(url);

    const createDataCollectionStudy = useCallback(() => {
        const payload = {};
        return request(payload);
    }, [request]);

    const results = useMemo(() => {
        return data ? parseDataCollectionStudy(data) : null;
    }, [data]);

    return [createDataCollectionStudy, {
        results,
        loading,
        error
    }]
}
