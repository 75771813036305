import {useCallback, useState} from 'react';
import {ParameterComparisonResults} from "../../../../../../../../types/ParameterComparisonResults";
import Button from "@material-ui/core/Button";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {ParameterComparison} from "../../../../../../../../types/ParameterComparison";
import {
    getDefaultSortingForParametersTable,
    nameSortComparator,
    parameterValueFormatter
} from "../../../../utils/tableUtils";
import {DataGrid} from "@material-ui/data-grid";
import {getCommonColumnProperties} from "../../../../../../utils/tableUtils";
import {v4 as uuid} from "uuid";

interface ParameterComparisonTableProps {
    results: ParameterComparisonResults;
    updateParameters: any;
}

const renderWarning = (params: any) => params.value ?
    <p style={{color: 'red'}}>Yes</p> :
    <p style={{color: 'green'}}>No</p>;

const headCells = [
    {field: 'name', sortComparator: nameSortComparator},
    {field: 'willBeUpdated', type: 'boolean'},
    {field: 'tooBigLocChange', type: 'boolean', renderCell: renderWarning},
    {field: 'tooBigScaleChange', type: 'boolean', renderCell: renderWarning},
    {field: 'tooHighPosteriorCertainty', type: 'boolean', renderCell: renderWarning},
    {field: 'strangeStatusChange', type: 'boolean', renderCell: renderWarning},
    {field: 'currentStatus'},
    {field: 'newStatus'},
    {field: 'locEapCurrent', valueFormatter: parameterValueFormatter},
    {field: 'locEapNew', valueFormatter: parameterValueFormatter},
    {field: 'locPsdCurrent', valueFormatter: parameterValueFormatter},
    {field: 'locPsdNew', valueFormatter: parameterValueFormatter},
    {field: 'scaleEapCurrent', valueFormatter: parameterValueFormatter},
    {field: 'scaleEapNew', valueFormatter: parameterValueFormatter},
    {field: 'scalePsdCurrent', valueFormatter: parameterValueFormatter},
    {field: 'scalePsdNew', valueFormatter: parameterValueFormatter},
    {field: 'guessingEapCurrent', valueFormatter: parameterValueFormatter},
    {field: 'guessingEapNew', valueFormatter: parameterValueFormatter},
    {field: 'guessingPsdCurrent', valueFormatter: parameterValueFormatter},
    {field: 'guessingPsdNew', valueFormatter: parameterValueFormatter}
].map(cell => ({...getCommonColumnProperties({width: 200}), ...cell}));

export function ParameterComparisonTable({results, updateParameters}: ParameterComparisonTableProps) {
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const getRows = useCallback((params: ParameterComparison[]) => {
        return params.map((p: ParameterComparison) => {
            return Object.assign({'id': uuid()}, p)
        });
    }, []);

    return (
        <div>
            <h2>Parameter comparison</h2>
            <p>
                Number of questions that will be updated:
                <b>{results.parameterComparisons.filter(p => p.willBeUpdated).length}</b>
            </p>
            <p>Number of warnings: <b>{results.parametersWithWarnings}</b></p>
            <DataGrid
                rows={getRows(results.parameterComparisons)}
                columns={headCells}
                pageSize={20}
                autoHeight={true}
                sortModel={getDefaultSortingForParametersTable()}
                disableSelectionOnClick={true}
            />
            <Button color={'primary'} variant={'contained'} onClick={() => setIsDialogOpen(true)}>
                Update parameters
            </Button>
            <Dialog
                open={isDialogOpen}
                onClose={() => setIsDialogOpen(false)}
            >
                <DialogTitle>Are you sure?</DialogTitle>
                <DialogContent>
                    <DialogContentText>Parameters will be updated</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsDialogOpen(false)} color='secondary'>
                        No
                    </Button>
                    <Button onClick={updateParameters} color='primary'>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
