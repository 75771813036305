import {useCallback, useMemo} from "react";
import {parseParameterComparisonResults} from "./utils";
import {ParameterComparisonResults} from "../types/ParameterComparisonResults";
import {useApiOperation} from "./useApiOperation";

interface CompareParametersStatus {
    parameterComparisonResults: ParameterComparisonResults | null;
    loading: boolean;
    error: string | null;
}

type CompareParametersReturn = [() => Promise<void>, CompareParametersStatus];

export function useCompareParameters(tableName: string): CompareParametersReturn {
    const url = '/api/parameters.compare';
    const [request, {loading, error, data}] = useApiOperation(url);

    const compareParameters = useCallback(() => {
        const payload = {
            tableName
        };
        return request(payload);
    }, [request, tableName]);

    const parameterComparisonResults = useMemo(() => {
        return data ? parseParameterComparisonResults(data) : null;
    }, [data]);

    return [compareParameters, {
        parameterComparisonResults,
        loading,
        error
    } as CompareParametersStatus]
}
