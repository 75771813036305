import {Redirect} from 'react-router-dom';
import {QuestionPatterns} from "./components/QuestionPatterns";
import {QuestionOptions} from "./components/QuestionOptions";
import {Parameters} from "./components/Parameters";
import {Statistics} from "./components/Statistics";
import styled from "styled-components";
import {getToken} from "../../../../../../services/auth/helpers";
import {Spinner} from "../../../../../../components/Spinner";
import {useQuestion} from "../../../../../../api/useQuestion";
import {QuestionStatus} from "./components/QuestionStatus";

interface QuestionProps {
    questionId: string;
}

export function Question({questionId}: QuestionProps) {
    const {questionWithMetrics, loading, error, refetch} = useQuestion(questionId);
    const authorizationToken = getToken();

    if (!authorizationToken) {
        return <Redirect to={'/login'}/>
    }

    if (loading) {
        return <Spinner/>;
    }

    if (error) {
        return (
            <p>
                {error}
            </p>
        );
    }

    return (
        <div data-name={questionWithMetrics?.question.name}>
            <div id={'info'}>
                <QuestionInfo>
                    {questionWithMetrics?.question.name} - {questionWithMetrics?.id}
                </QuestionInfo>
                <QuestionStatus question={questionWithMetrics?.question} onStatusChanged={refetch}/>
                <QuestionPatterns question={questionWithMetrics?.question}/>
                <QuestionOptions question={questionWithMetrics?.question}/>
                <CorrectOption>Correct option: {questionWithMetrics?.question.correctOption}</CorrectOption>
            </div>
            <Parameters question={questionWithMetrics?.question}/>
            <Statistics questionWithMetrics={questionWithMetrics}/>
        </div>
    )
}

const CorrectOption = styled.div`
  padding: 20px;
`;

const QuestionInfo = styled.div`
  padding-top: 20px;
  font-weight: bold;
`;
