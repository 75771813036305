import {useEffect} from 'react';
import {clearToken} from "../../services/auth/helpers";
import { Link } from 'react-router-dom';

export function Logout() {
    useEffect(() => {
        clearToken();
    }, []);

    return <Link to={'/login'}><button>Login again</button></Link>;
}
