import {useCallback, useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, CircularProgress} from '@material-ui/core';
import {usePreviewRecalculationJob} from "../../../../../../../api/usePreviewRecalculationJob";
import {useCreateRecalculationJob} from "../../../../../../../api/useCreateRecalculationJob";
import {Redirect} from "react-router-dom";
import {LINKS} from "../../../../../links";
import {formatDate, secondsToDaysHoursMinutesSeconds} from "../../../../../../../utils";


interface StartRecalculationJobProps {
    fromDate: string | Date;
    toDate: string | Date;
    recalculatedBeforeDate: string | Date;
    isDisabled: boolean;
}

export function StartRecalculationJob({fromDate, toDate, recalculatedBeforeDate, isDisabled}: StartRecalculationJobProps) {
    const [showCreateJob, setShowCreateJob] = useState(false);
    const [doPreviewJob, {jobPreview, loading: loadingPreview}] = usePreviewRecalculationJob();
    const [doCreateRecalculationJob, {recalculationJobId, loading: loadingCreateJob}] = useCreateRecalculationJob();
    const previewJob = useCallback(() => {
        doPreviewJob(fromDate, toDate, recalculatedBeforeDate).then(() => {
            console.log('Completed');
        });
    }, [doPreviewJob, fromDate, toDate, recalculatedBeforeDate]);

    const createRecalculationJob = useCallback(() => {
        doCreateRecalculationJob(fromDate, toDate, recalculatedBeforeDate).then(() => {
            console.log('Job created');
        });
    }, [doCreateRecalculationJob, fromDate, toDate, recalculatedBeforeDate]);

    useEffect(() => {
        if (jobPreview) {
            setShowCreateJob(true);
        } else {
            setShowCreateJob(false);
        }
    }, [jobPreview]);

    useEffect(() => {
        setShowCreateJob(false);
    }, [fromDate, toDate]);


    const formattedFromDate = useMemo(() => formatDate(fromDate), [fromDate]);
    const formattedToDate = useMemo(() => formatDate(toDate), [toDate]);
    const formattedRecalculatedBeforeDate = useMemo(() => formatDate(recalculatedBeforeDate, true), [recalculatedBeforeDate]);

    if (recalculationJobId) {
        return <Redirect to={LINKS.recalculation()}/>
    }

    if (showCreateJob) {
        return (
            <div>
                <div>
                    This job will recalculate {jobPreview?.totalTests} tests.
                    {jobPreview?.totalTests !== undefined && jobPreview.totalTests > 0 &&
                    <div>
                        <p>
                            Estimated recalculation job length
                            is {secondsToDaysHoursMinutesSeconds(jobPreview.totalTests)}.
                        </p>
                        <i>The estimated recalculation job length may be off due to uncompleted tasks from previous running jobs</i>
                    </div>}
                </div>
                <div>
                    <Button
                        onClick={createRecalculationJob}
                        variant="contained"
                        disabled={loadingCreateJob || isDisabled}
                    >
                        {loadingCreateJob ?
                            <div style={{height: 36}}><CircularProgress size={16}/></div> : "Start job!"}
                    </Button>
                </div>
            </div>
        );
    }

    return (
        <div>
            <div>
                {(formattedFromDate && formattedToDate && recalculatedBeforeDate) ?
                    `Including tests which are completed between ${formattedFromDate} to ${formattedToDate} and 
                    recalculated before ${formattedRecalculatedBeforeDate}`
                    : null}
            </div>
            <div>
                <Button
                    onClick={previewJob}
                    variant="contained"
                    disabled={loadingPreview}
                >
                    {loadingPreview ? <CircularProgress size={16}/> : "Preview!"}
                </Button>
            </div>
        </div>
    );
}

StartRecalculationJob.propTypes = {
    fromDate: PropTypes.instanceOf(Date),
    toDate: PropTypes.instanceOf(Date),
    recalculatedBeforeDate: PropTypes.instanceOf(Date),
    isDisabled: PropTypes.bool
};

