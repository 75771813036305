import {v4 as uuid} from 'uuid';
import {ALVA_TOKEN_KEY} from "./constants";


export const getToken = () => {
    return localStorage.getItem(ALVA_TOKEN_KEY);
};

export const clearToken = () => {
    localStorage.removeItem(ALVA_TOKEN_KEY);
};

export const buildRequestHeaders = () => {
    let headers: any = {};

    headers['Content-Type'] = 'application/json';
    let token = getToken();

    headers['Alva-Correlation-Id'] = uuid();
    headers['Authorization'] = `Bearer ${token}`;

    return headers;
};


// TODO: use this
export const setTokenFromHeader = (response: Response) => {
    const token = response.headers.get('Authorization');

    if (token) {
        setToken(token);
    }

    return response;
};

export function setToken(token: string) {
    localStorage.setItem(ALVA_TOKEN_KEY, token);
}

