import {useCallback} from "react";
import {Link, Redirect, useLocation} from 'react-router-dom';
import styled from "styled-components";
import {getToken} from "../../../../services/auth/helpers";
import {Spinner} from "../../../../components/Spinner";
import {useDataCollectionStudies} from "../../../../api/useDataCollectionStudies";
import {useCreateDataCollectionStudy} from "../../../../api/useCreateDataCollectionStudy";
import Button from "@material-ui/core/Button";
import {Switch} from "@material-ui/core";
import {useUpdateDataCollectionStudy} from "../../../../api/useUpdateDataCollectionStudy";


export function DataCollection() {
    const {dataCollectionStudies, loading, error, refetch} = useDataCollectionStudies();
    const [createDataCollectionStudy] = useCreateDataCollectionStudy();
    const [updateDataCollectionStudy] = useUpdateDataCollectionStudy();

    const handleCreateDataCollectionStudy = useCallback(() => {
        createDataCollectionStudy();
        refetch();
    }, [createDataCollectionStudy, refetch]);

    const handleUpdateDataCollectionStudy = useCallback((studyId: string) => {
        updateDataCollectionStudy(studyId);
        refetch();
    }, [updateDataCollectionStudy, refetch]);

    const authorizationToken = getToken();
    const location = useLocation()

    if (!authorizationToken) {
        return <Redirect to={'/login'}/>;
    }

    if (loading) {
        return <Spinner/>;
    }

    if (error) {
        return (
            <p>
                {error}
            </p>
        );
    }

    return (
        <Wrapper>
            <Main>
                <Button
                    color={'primary'}
                    variant={'outlined'}
                    onClick={handleCreateDataCollectionStudy}>Create new study</Button>
                <TableWrapper>
                    <thead>
                    <tr>
                        <Header>Study id</Header>
                        <Header>Enabled</Header>
                    </tr>
                    </thead>
                    <tbody>
                    {dataCollectionStudies.map((study: { studyId: string; dataCollectionEnabled: boolean }) =>
                        <tr key={study.studyId}>
                            <Field>
                                <Link to={`${location.pathname}${study.studyId}`}>
                                    {study.studyId}
                                </Link>
                            </Field>
                            <Field>
                                <Switch
                                    checked={study.dataCollectionEnabled}
                                    onChange={(_, checked) => handleUpdateDataCollectionStudy(study.studyId)}
                                    color={"primary"}
                                />
                            </Field>
                        </tr>
                    )}
                    </tbody>
                </TableWrapper>

            </Main>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    display: flex;
`;

const Main = styled.div`
    flex: 1;
`;


const TableWrapper = styled.table`
  padding: 20px;
  margin: auto;
`;

const Header = styled.th`
  padding: 10px;
  border: solid 1px;
  background-color: burlywood;
`;

const Field = styled.td`
  padding: 10px;
  border: solid 1px;
`;

