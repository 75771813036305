import {useCallback} from 'react';
import {DataGrid} from '@material-ui/data-grid';
import {useCurrentParameters} from "../../../../../../api/useCurrentParameters";
import {QuestionParameters} from "../../../../../../types/QuestionParameters";
import {getDefaultSortingForParametersTable, nameSortComparator, parameterValueFormatter} from "../../utils/tableUtils";
import {getCommonColumnProperties} from "../../../../utils/tableUtils";
import {v4 as uuid} from "uuid";

const columns = [
    {field: 'name', sortComparator: nameSortComparator},
    {field: 'updatedAt', type:'date'},
    {field: 'status'},
    {field: 'locEap', type: 'number', valueFormatter: parameterValueFormatter},
    {field: 'locPsd', type: 'number', valueFormatter: parameterValueFormatter},
    {field: 'scaleEap', type: 'number', valueFormatter: parameterValueFormatter},
    {field: 'scalePsd', type: 'number', valueFormatter: parameterValueFormatter},
    {field: 'guessingEap', type: 'number', valueFormatter: parameterValueFormatter},
    {field: 'guessingPsd', type: 'number', valueFormatter: parameterValueFormatter}
].map(cell => ({...getCommonColumnProperties({flex: 1, minWidth: 150}), ...cell}));

export function CurrentParameters() {
    const {loading, error, parameters} = useCurrentParameters();

    const getRows = useCallback(() => {
        return parameters.map((p: QuestionParameters) => {
            return Object.assign({'id': uuid()}, p)
        });
    }, [parameters]);

    if (error) {
        return <h2>Failed to load current parameters, try refreshing the page.</h2>;
    }

    return (
        <div>
            <h2>Current parameters</h2>
            <DataGrid
                rows={getRows()}
                columns={columns}
                pageSize={20}
                autoHeight={true}
                loading={loading}
                sortModel={getDefaultSortingForParametersTable()}
                disableSelectionOnClick={true}
            />
        </div>
    );
}
