import {useMemo} from "react";
import {parseRecalculationJobStatuses} from "./utils";
import {useApiCall} from "./useApiCall";
import {RecalculationJobStatuses} from "../types/RecalculationJobStatuses";

interface UseRecalculationJobsReturn {
    recalculationJobStatuses?: RecalculationJobStatuses | null;
    loading: boolean;
    error: string | null;
    refetch: () => void
}

export function useRecalculationJobs(): UseRecalculationJobsReturn {
    const url = '/api/recalculationJobs.list';
    const {data, error, loading, refetch} = useApiCall(url);
    const recalculationJobStatuses = useMemo(() => {
        if (data) {
            return parseRecalculationJobStatuses(data);
        }
        return null;
    }, [data]);

    return {
        recalculationJobStatuses,
        loading,
        error,
        refetch
    }
}
