import styled from "styled-components";
import {QuestionWithMetrics} from "../../../../../../../../types/QuestionWithMetrics";

interface StatisticsProps {
    questionWithMetrics?: QuestionWithMetrics | null
}

export function Statistics({questionWithMetrics}: StatisticsProps) {

    if (!questionWithMetrics) {
        return null;
    }

    const statistics = [
        {
            name: 'Number of observations',
            value: questionWithMetrics.numberOfObservations,
            percentage: questionWithMetrics.numberOfObservations > 0 ?
                questionWithMetrics.numberOfObservations / questionWithMetrics.numberOfObservations * 100 : null
        },
        {
            name: 'Number answers with option a',
            value: questionWithMetrics.numberOfOptionAAnswers,
            percentage: questionWithMetrics.numberOfObservations > 0 ?
                questionWithMetrics.numberOfOptionAAnswers / questionWithMetrics.numberOfObservations * 100 : null
        },
        {
            name: 'Number answers with option b',
            value: questionWithMetrics.numberOfOptionBAnswers,
            percentage: questionWithMetrics.numberOfObservations > 0 ?
                questionWithMetrics.numberOfOptionBAnswers / questionWithMetrics.numberOfObservations * 100 : null
        },
        {
            name: 'Number answers with option c',
            value: questionWithMetrics.numberOfOptionCAnswers,
            percentage: questionWithMetrics.numberOfObservations > 0 ?
                questionWithMetrics.numberOfOptionCAnswers / questionWithMetrics.numberOfObservations * 100 : null
        },
        {
            name: 'Number answers with option d',
            value: questionWithMetrics.numberOfOptionDAnswers,
            percentage: questionWithMetrics.numberOfObservations > 0 ?
                questionWithMetrics.numberOfOptionDAnswers / questionWithMetrics.numberOfObservations * 100 : null
        },
        {
            name: 'Number answers with option e',
            value: questionWithMetrics.numberOfOptionEAnswers,
            percentage: questionWithMetrics.numberOfObservations > 0 ?
                questionWithMetrics.numberOfOptionEAnswers / questionWithMetrics.numberOfObservations * 100 : null
        },
        {
            name: 'Number answers with option f',
            value: questionWithMetrics.numberOfOptionFAnswers,
            percentage: questionWithMetrics.numberOfObservations > 0 ?
                questionWithMetrics.numberOfOptionFAnswers / questionWithMetrics.numberOfObservations * 100 : null
        },
        {
            name: 'Number of skipped answers',
            value: questionWithMetrics.numberOfSkippedAnswers,
            percentage: questionWithMetrics.numberOfObservations > 0 ?
                questionWithMetrics.numberOfSkippedAnswers / questionWithMetrics.numberOfObservations * 100 : null
        }
    ];

    return (
        <TableWrapper>
            <thead>
            <tr>
                <Header>Statistics</Header>
                <Header>Value</Header>
                {questionWithMetrics.numberOfObservations > 0 ?
                    <Header>Percentage %</Header> : null
                }
            </tr>
            </thead>
            <tbody>
            {statistics && statistics.map((statistic: { name: string; value: number | null; percentage: number | null }) =>
                <tr key={statistic.name}>
                    <Field>
                        {statistic.name}
                    </Field>
                    <Field>
                        {statistic.value || 0}
                    </Field>
                    {questionWithMetrics.numberOfObservations > 0 ?
                        <Field>
                            {statistic.percentage && statistic.percentage.toFixed(2) + ' %'}
                        </Field> : null
                    }
                </tr>
            )}
            </tbody>
        </TableWrapper>
    )
}

const Header = styled.th`
  padding: 10px;
  border: solid 1px;
  background-color: burlywood;
`;

const Field = styled.td`
  padding: 10px;
  border: solid 1px;
`;

const TableWrapper = styled.table`
  margin: auto;
  padding: 20px;
`;
