import React from 'react';
import './App.css';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import {Login} from "./pages/Login";
import {Home} from "./pages/Home";
import {VerifyGoogleLogin} from "./pages/VerifyGoogleLogin";
import WithRequireAuthentication from "./services/auth/WithRequireAuthentication";
import { Logout } from './pages/Logout';

function App() {
    return (
        <div className="App">
            <BrowserRouter>
                <Switch>
                    <Route path="/login" render={() => <Login/>}/>
                    <Route path="/logout" render={() => <Logout/>}/>
                    [// @ts-ignore]
                    <Route path="/verify-google-login" render={() => <VerifyGoogleLogin/>}/>
                    <Route path="*" render={() => <WithRequireAuthentication><Home/></WithRequireAuthentication>}/>
                </Switch>
            </BrowserRouter>
        </div>
    );
}

export default App;
