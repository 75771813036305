import {ChangeEvent, useCallback, useMemo, useState} from "react";
import {Redirect, useParams} from 'react-router-dom';
import styled from "styled-components";
import {getToken} from "../../../../services/auth/helpers";
import {Spinner} from "../../../../components/Spinner";
import Button from "@material-ui/core/Button";
import {Input, Link, Switch} from "@material-ui/core";
import {useUpdateDataCollectionStudy} from "../../../../api/useUpdateDataCollectionStudy";
import {useDataCollectionStudy} from "../../../../api/useDataCollectionStudy";
import {useGetDataCollectionStudyParticipants} from "../../../../api/useGetDataCollectionStudyParticipants";
import {useAddDataCollectionStudyParticipants} from "../../../../api/useUpdateDataCollectionStudyParticipants";
import {getConfig} from "../../../../config";

const DATA_COLLECTION_PATH = 'logic-test-data-collection';

export function DataCollectionStudy() {
    const params = useParams();

    // @ts-ignore
    const studyId = params.studyId;

    const {dataCollectionStudy, loading, error, refetch} = useDataCollectionStudy(studyId);
    const [addParticipants] = useAddDataCollectionStudyParticipants();
    const {
        dataCollectionStudyParticipants,
        refetch: refetchParticipants
    } = useGetDataCollectionStudyParticipants(studyId);

    const [updateDataCollectionStudy] = useUpdateDataCollectionStudy();

    const handleUpdateDataCollectionStudy = useCallback((studyId: string) => {
        updateDataCollectionStudy(studyId);
        refetch();
    }, [updateDataCollectionStudy, refetch]);

    const [inputText, setInputText] = useState('');

    const parsedExternalIds = useMemo(() => {
        // @ts-ignore
        return [...new Set(inputText.split(/[^\w-]+/).filter(i => i !== ''))]
    }, [inputText]);

    const handleInputChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setInputText(event.target.value);
    }, []);

    const handleAddDataCollectionStudyParticipants = useCallback(() => {
        addParticipants(studyId, parsedExternalIds);
        refetchParticipants();
        setInputText('');
    }, [refetchParticipants, addParticipants, parsedExternalIds, studyId]);

    const authorizationToken = getToken();


    function getDataCollectionUrl(studyId: string): string {
        const config = getConfig();
        return `${config.ALVA_APP_BASE_URL}/${DATA_COLLECTION_PATH}/${studyId}?source=prolific&PROLIFIC_PID={{%PROLIFIC_PID%}}`;
    }

    if (!authorizationToken) {
        return <Redirect to={'/login'}/>;
    }

    if (loading) {
        return <Spinner/>;
    }
    if (!dataCollectionStudy) {
        return <p>Not found</p>
    }

    if (error) {
        return (
            <p>
                {error}
            </p>
        );
    }

    return (
        <Wrapper>
            <Main>
                Study Id: <b>{studyId}</b>
                <br/>
                Enable/Disable Study
                <Switch
                    checked={dataCollectionStudy.dataCollectionEnabled}
                    onChange={(_, checked) => handleUpdateDataCollectionStudy(dataCollectionStudy.studyId)}
                    color={"primary"}
                />
                <br/>
                <Link
                    href={getDataCollectionUrl(studyId)}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {getDataCollectionUrl(studyId)}
                </Link>
                <div>
                    <br/>
                    {parsedExternalIds.length > 0 && <span>Parsed external ids: <b>{parsedExternalIds.join(', ')}</b></span>}
                    <br/>
                    <br/>
                    <div style={{maxWidth: '360px', margin: 'auto'}}>
                        <Input multiline={true} fullWidth={true} rows={5} rowsMax={70} value={inputText} onChange={handleInputChange}/>
                    </div>
                </div>

                <br/>
                <Button
                    color={'primary'}
                    variant={'outlined'}
                    onClick={handleAddDataCollectionStudyParticipants}>Add new external ids</Button>
                <TableWrapper>
                    <thead>
                    <tr>
                        <Header>Participant External Id</Header>
                    </tr>
                    </thead>
                    <tbody>
                    {dataCollectionStudyParticipants.map((participant: string) =>
                        <tr key={participant}>
                            <Field>
                                    {participant}
                            </Field>
                        </tr>
                    )}
                    </tbody>
                </TableWrapper>

            </Main>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    display: flex;
`;

const Main = styled.div`
    flex: 1;
`;


const TableWrapper = styled.table`
  padding: 20px;
  margin: auto;
`;

const Header = styled.th`
  padding: 10px;
  border: solid 1px;
  background-color: burlywood;
`;

const Field = styled.td`
  padding: 10px;
  border: solid 1px;
`;

