import styled from "styled-components";
import {Question} from '../../../../../../../../types/Question';

export function QuestionOptions({question}: { question?: Question; }) {

    const renderOptionsRow = (options: { key: string; data: string; }[]) => {

        return (
            <OptionsRow>
                {options.map((option: { key: string; data: string; }) => (
                    <OptionItemWrapper key={option.key}>
                        <OptionItem src={`data:image/svg+xml;base64, ${option.data}`}/>
                    </OptionItemWrapper>)
                )}
            </OptionsRow>
        );
    };

    if (!question) {
        return null;
    }

    const firstRow = [
        {key: 'option1', data: question.optionA},
        {key: 'option2', data: question.optionB},
        {key: 'option3', data: question.optionC},
    ];
    const secondRow = [
        {key: 'option4', data: question.optionD},
        {key: 'option5', data: question.optionE},
        {key: 'option6', data: question.optionF},
    ];

    return (
        <Wrapper>
            {renderOptionsRow(firstRow)}
            {renderOptionsRow(secondRow)}
        </Wrapper>
    );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  @media only screen and (max-width: 760px) {
    margin-top: 8px;
  }
  @media only screen and (min-width: 761px) and (max-width: 800px) {
    margin-top: 18px;
  }
`;

const OptionsRow = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  flex: none;

  &:last-child {
    margin-bottom: 0;
  }

  @media only screen and (max-width: 370px) {
    margin-bottom: 12px;
  }
  @media only screen and (min-width: 371px) and (max-width: 760px) {
    margin-bottom: 16px;
  }
`;

const OptionItem = styled.img`
  height: 80px;
  width: 80px;
  cursor: pointer;
`;

const OptionItemWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin: 0 10px 0 10px;
  background-color: #FFFFFF;
  border: 2px solid white;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.08);
  border-radius: 2px;

  @media only screen and (max-width: 370px) {
    margin: 0 6px 0 6px;
    padding: 3px;
  }
  @media only screen and (min-width: 371px) and (max-width: 760px) {
    margin: 0 8px 0 8px;
  }
`;
