import {GridSortDirection} from "@material-ui/data-grid";
import {roundValue} from "../../../../../utils";

function getBooleanAsNumber(value: boolean | null): number {
    if (value === null) {
        return -1;
    }

    return Number(value);
}

export function booleanSortComparator(v1: any, v2: any): number {
    const number1 = getBooleanAsNumber(v1);
    const number2 = getBooleanAsNumber(v2);
    return number1 - number2;
}

export function getDefaultSortingForParametersTable() {
    return [{
        field: 'name',
        sort: 'asc' as GridSortDirection
    }];
}

export function nameSortComparator(v1: any, v2: any): number {
    return sortFunction(v1, v2);
}

function getQuestionNumber(name?: any): number | null {
    if (!name) {
        return -1;
    }

    const parts = name.split('_');
    if (parts.length < 2) {
        return null;
    }

    return parseInt(parts[1]);
}

function sortFunction(name1: any, name2: any): number {
    const questionNumber1 = getQuestionNumber(name1);
    const questionNumber2 = getQuestionNumber(name2);

    if (questionNumber1 === null || questionNumber2 === null) {
        return name1.localeCompare(name2);
    }

    return questionNumber1 - questionNumber2;
}

export const parameterValueFormatter = (params: any) => params.value === null ? null : roundValue(params.value);
