import React from 'react';
import questionMark from './assets/questionmark.svg';
import styled from "styled-components";
import {Question} from "../../../../../../../../types/Question";

interface QuestionPatternsProps {
    question?: Question;
}

export function QuestionPatterns({question}: QuestionPatternsProps) {

    const renderPatternsRow = (patterns: { key: string; data: string; }[]) => {
        return (
            <PatternsRow>
                {patterns.map((pattern: { key: string; data: string; }) => (
                    <PatternItemWrapper key={pattern.key}>
                        {pattern.key === 'questionMark' ? (
                            <PatternItem src={pattern.data}/>
                        ) : (
                            <PatternItem src={`data:image/svg+xml;base64, ${pattern.data}`}/>
                        )}
                    </PatternItemWrapper>
                ))}
            </PatternsRow>
        );
    };

    if (!question) {
        return null;
    }

    const firstRow = [
        {key: 'pattern1', data: question.pattern1},
        {key: 'pattern2', data: question.pattern2},
        {key: 'pattern3', data: question.pattern3}
    ];
    const secondRow = [
        {key: 'pattern4', data: question.pattern4},
        {key: 'pattern5', data: question.pattern5},
        {key: 'pattern6', data: question.pattern6}
    ];
    const thirdRow = [
        {key: 'pattern7', data: question.pattern7},
        {key: 'pattern8', data: question.pattern8},
        {key: 'questionMark', data: questionMark}
    ];

    return (
        <Wrapper>
            {renderPatternsRow(firstRow)}
            {renderPatternsRow(secondRow)}
            {renderPatternsRow(thirdRow)}
        </Wrapper>
    );
}

const Wrapper = styled.div`
   flex: none;
   width: 100%;
   display: flex;
   flex-direction: column;
   justify-content: center;
   margin-top: 20px;
   @media only screen and (max-width: 760px) {
     padding: 16px 0 16px 0;
   }
   @media only screen and (min-width: 761px) and (max-width: 800px)  {
     padding: 20px 0 40px 0;
   }
`;

const PatternsRow = styled.div`  
  display: flex;
  flex: none;
  justify-content: center;
  @media only screen and (max-width: 800px) {
    margin: 0;
  }
`;

const PatternItemWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 19px;
  margin: 0;
  background-color: #FFFFFF;
  box-shadow: 0 0;
  border-radius: 1px;
  border-color: grey;
  border-style: solid;
  border-width: 1px;
  @media only screen and (max-width: 370px) {
    padding: 8px;
  }
`;

const PatternItem = styled.img`  
  height: 80px;
  width: 80px;
`;
