import {useCallback, useEffect, useState} from "react";
import styled from "styled-components";
import {Dropzone} from "./components/Dropzone";
import {Button, CircularProgress, InputLabel} from "@material-ui/core";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {Item} from "../../../../types/Item";
import {useUploadQuestions} from "../../../../api/useUploadQuestions";
import {useHistory} from "react-router-dom";
import {LINKS} from "../../links";


const PATTERN_KEY = 'pattern';
const OPTION_KEY = 'option';
// typescript does not let us get interface keys directly, there are ways to achieve it but we probably don't need it
// https://stackoverflow.com/questions/43909566/get-keys-of-a-typescript-interface-as-array-of-strings
const PATTERN_KEYS = Array.from({length: 8}, (v, k) => k + 1).map(i => `${PATTERN_KEY}${i}`);
const OPTION_KEYS = Array.from({length: 6}, (v, k) => k + 1).map(i => `${OPTION_KEY}${i}`);
const ANSWER_KEY = 'answer';
const ANSWER_OPTIONS = ['A', 'B', 'C', 'D', 'E', 'F'];

export function UploadItems() {
    const [areItemsValid, setAreItemsValid] = useState<boolean>(false);
    const [items, setItems] = useState<{ [id: string]: Item }>({});
    const history = useHistory();
    const [uploadQuestions, {error, loading}] = useUploadQuestions(() => {
        history.push(LINKS.questions());
    });


    const onDrop = useCallback((acceptedFiles: File[]) => {
        acceptedFiles.forEach((file: File) => {
            const reader = new FileReader();
            reader.onabort = () => console.log('file reading was aborted');
            reader.onerror = () => console.log('file reading has failed');
            const namePattern = /^(Q\d+)-(A-0*[123456]|Q-0*[12345678])\.svg$/i;
            reader.onload = () => {
                const content = reader.result as string;
                const fileName = file.name.toLowerCase();
                const match = fileName.match(namePattern);
                if (!namePattern.test(fileName) || !match) {
                    return;
                }
                const name = match[1];
                const section = match[2];
                setItems(existingState => {
                    const selectedItem: Item = existingState[name] || {};
                    const [sectionType, sectionNum] = section.split('-');
                    const key = `${sectionType === 'q' ? PATTERN_KEY : OPTION_KEY}${sectionNum.replaceAll('0', '')}`;

                    // @ts-ignore
                    selectedItem[key as keyof Item] = btoa(content);
                    return {...existingState, [name]: selectedItem}
                });
            }
            reader.readAsText(file);
        });
    }, [])

    const reset = useCallback(() => {
        setAreItemsValid(false);
        setItems({});
    }, []);

    const removeItem = useCallback((itemName: string) => {
        setItems(existingItems => {
            const tempItems = {...existingItems}
            if (itemName in tempItems) {
                delete tempItems[itemName];
            }
            return tempItems;
        })
    }, []);

    const handleSelectAnswer = useCallback((item: string, event: any) => {
        if (!(item in items)) {
            return;
        }
        const tempItems = {...items};
        tempItems[item].answer = event.target.value;
        setItems(tempItems);
    }, [items]);

    useEffect(() => {
        if (Object.keys(items).length === 0) {
            setAreItemsValid(false);
            return;
        }
        const allKeys = [...PATTERN_KEYS, ...OPTION_KEYS, ANSWER_KEY];
        for (const itemKey in items) {
            const item = items[itemKey];
            for (const i in allKeys) {
                const key = allKeys[i];
                if (!(key in item) || item[key as keyof Item] === null) {
                    setAreItemsValid(false);
                    return;
                }
            }
        }
        setAreItemsValid(true);
    }, [items]);

    const getUrlForBase64SvgContent = (content: string) => `data:image/svg+xml;base64, ${content}`;

    return (
        <Wrapper>
            <h2>Upload items</h2>
            <Dropzone onDrop={onDrop}/>
            <ActionWrapper>
                <Button
                    onClick={reset}
                    variant='contained'
                >
                    {loading ? <CircularProgress size={16}/> : 'Clear'}
                </Button>
                <Button
                    onClick={uploadQuestions.bind(null, items)}
                    color='primary'
                    variant='contained'
                    disabled={!areItemsValid || loading}
                >
                    {loading ? <CircularProgress size={16}/> : 'Upload!'}
                </Button>
            </ActionWrapper>
            {error}

            {Object.keys(items).sort().map((key) =>
                <ItemWrapper key={key}>
                    <b>{key}</b>
                    <ItemGridWrapper>
                        <QuestionGrid>
                            {PATTERN_KEYS.map(patternKey => {
                                if (patternKey in items[key]) {
                                    return <PatternItem key={patternKey}
                                                        url={getUrlForBase64SvgContent(items[key][patternKey as keyof Item] as string)}/>
                                }
                                return <MissingItem key={patternKey}/>
                            })}
                        </QuestionGrid>
                        <OptionGrid>
                            {OPTION_KEYS.map(optionKey => {
                                if (optionKey in items[key]) {
                                    return <PatternItem key={optionKey}
                                                        url={getUrlForBase64SvgContent(items[key][optionKey as keyof Item] as string)}/>
                                }
                                return <MissingItem key={optionKey}/>
                            })}
                        </OptionGrid>
                        <ItemActionWrapper>
                            <SelectWrapper>
                                <Label>Answer</Label>
                                <Select
                                    required
                                    value={!!items[key].answer ? items[key].answer : 'Select ...'}
                                    onChange={handleSelectAnswer.bind(null, key)}
                                    disabled={loading}
                                    autoWidth={true}
                                >
                                    {!items[key].answer && <MenuItem value={'Select ...'}>Select ...</MenuItem>}
                                    {ANSWER_OPTIONS.map(option => (
                                        <MenuItem key={`option-${option}`} value={option}>{option}</MenuItem>
                                    ))}
                                </Select>
                            </SelectWrapper>
                            <Button
                                onClick={removeItem.bind(null, key)}
                                variant='contained'
                                disabled={loading}
                            >
                                {loading ? <CircularProgress size={16}/> : 'Remove'}
                            </Button>
                        </ItemActionWrapper>
                    </ItemGridWrapper>
                </ItemWrapper>
            )}
        </Wrapper>
    );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ActionWrapper = styled.div`
  margin-top: 8px;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
`;

const PatternItem = styled.div<{ url: string; }>`  
  height: 80px;
  width: 80px;
  background: url('${({url}) => url}') center center no-repeat;
  background-size: 100%;
`;

const MissingItem = styled.div`  
  height: 80px;
  width: 80px;
  background: gray;
`;

const ItemWrapper = styled.div`
  padding: 8px;
  border: 1px solid black;
  border-radius: 4px;
  margin: auto;
`;

const ItemGridWrapper = styled.div`
  display: flex;
  gap: 32px;
  margin: auto;
  justify-content: center;
`;

const ItemActionWrapper = styled.div`
  margin: auto 0;
`;

const QuestionGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  aspect-ratio: 1/1;
  gap: 2px;
  margin: auto;
`

const OptionGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2px;
  margin: auto;
`

const SelectWrapper = styled.div`
  margin-bottom: 12px;
`;

const Label = styled(InputLabel)`
  margin-bottom: 12px;
`;
