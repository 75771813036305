import {useMemo} from "react";
import {useApiCall} from "./useApiCall";

interface UseAvailableTableIdsReturn {
    tableIds: string[];
    loading: boolean;
    error: string | null;
    refetch: () => void;
}

export function useAvailableTableIds(): UseAvailableTableIdsReturn {
    const url = '/api/parameters.listAvailableTableIds';
    const {loading, error, data, refetch} = useApiCall(url);

    const tableIds = useMemo(() => {
        return data && data.tableIds ? data.tableIds : [];
    }, [data]);

    return {
        tableIds,
        loading,
        error,
        refetch
    }
}
