import {Fragment, useCallback, useEffect, useMemo} from 'react';
import {Link} from "react-router-dom";
import {useRecalculationJobs} from "../../../../../api/useRecalculationJobs";
import {DataGrid, GridSortDirection} from "@material-ui/data-grid";
import Button from "@material-ui/core/Button";
import {RecalculationJob} from "../../../../../types/RecalculationJob";
import {useStopRecalculationJob} from "../../../../../api/useStopRecalculationJob";
import {LINKS} from "../../../links";
import {useUpdateActiveRecalculationJobStatus} from "../../../../../api/useUpdateActiveRecalculationJobStatus";
import {CircularProgress} from "@material-ui/core";
import {getCommonColumnProperties} from "../../../utils/tableUtils";


export function RecalculationJobs() {
    const {
        recalculationJobStatuses,
        loading: loadingRecalculationJobs,
        error: recalculationJobsError,
        refetch
    } = useRecalculationJobs();
    const [stopRecalculationJob, {loading: stoppingJob, error: stopJobError}] = useStopRecalculationJob();
    const [updateActiveRecalculationJobStatus] = useUpdateActiveRecalculationJobStatus();
    const isLoading = loadingRecalculationJobs;
    const hasError = !!recalculationJobsError || !!stopJobError;

    const handleChange = useCallback((recalculationJobId: string) => {
        stopRecalculationJob(recalculationJobId);
        refetch();
    }, [stopRecalculationJob, refetch]);

    const hasActiveJob = useMemo(() => {
        if (!recalculationJobStatuses || recalculationJobStatuses.jobs.length === 0) {
            return false;
        }

        return recalculationJobStatuses.jobs.some(j => j.status === 'ACTIVE');
    }, [recalculationJobStatuses]);

    const getActiveJobProgress = () => {
        if (!recalculationJobStatuses || recalculationJobStatuses.jobs.length === 0 || !hasActiveJob) {
            return 'N/A';
        }

        const totalTests = recalculationJobStatuses.jobs.find(
            j => j.status === 'ACTIVE')!.theoreticalNumberOfTestsToStart;
        const completedTests = recalculationJobStatuses!.activeJobCompletedTestCount;

        if (totalTests === 0 || completedTests === 0) {
            return '0%';
        }

        return `${(completedTests * 100 / totalTests).toFixed(1)}%`;
    }

    useEffect(() => {
        const timer = setInterval(() => {
            if (hasActiveJob) {
                updateActiveRecalculationJobStatus();
                refetch();
            }
        }, 20000);
        return () => clearInterval(timer);
    });

    const columns = [
        {id: 'recalculationJobId', field: 'recalculationJobId', width: 300},
        {id: 'createdAt', type: 'date', field: 'createdAt'},
        {id: 'status', field: 'status'},
        {id: 'createdBy', field: 'createdBy'},
        {id: 'targetBefore', type: 'date' ,field: 'targetBefore'},
        {id: 'targetAfter', type: 'date' ,field: 'targetAfter'},
        {id: 'targetRecalculatedBefore', type: 'dateTime', field: 'targetRecalculatedBefore', width: 300},
        {id: 'theoreticalNumberOfTestsToStart', field: 'theoreticalNumberOfTestsToStart', width: 300},
        {id: 'actualNumberOfTestsToStart', field: 'actualNumberOfTestsToStart', width: 250},
        {id: 'numberOfQueuedTests', field: 'numberOfQueuedTests'},
        {
            id: 'actions',
            field: 'button',
            headerName: 'actions',
            renderCell: (p: any) => (
                <Button
                    color={'primary'}
                    variant={'outlined'}
                    disabled={p.row.status !== 'ACTIVE'}
                    onClick={handleChange.bind(null, p.row.id)}
                >
                    {stoppingJob ? <CircularProgress size={23.5}/> : 'Stop job'}
                </Button>
            )
        }
    ].map(cell => ({...getCommonColumnProperties({width: 200}), ...cell}));

    const getRows = useCallback((jobs: RecalculationJob[]) => {
        return jobs.map((job: RecalculationJob) => {
            return Object.assign({'id': job.recalculationJobId}, job)
        });
    }, []);

    const newRecalculationJobUrl = useMemo(() => {
        const baseUrl = LINKS.newRecalculationJob();
        return `${baseUrl}?hasActiveJob=${hasActiveJob}`;
    }, [hasActiveJob]);

    return (
        <div>
            <h2>List of recalculation jobs!</h2>
            <div>
                <Link to={newRecalculationJobUrl}>
                    Create new job
                </Link>
            </div>
            {isLoading && <h3>Loading</h3>}
            {hasError && <h3>{recalculationJobsError} {stopJobError}</h3>}
            {!recalculationJobsError && (
                <Fragment>
                    <p>
                        Active job progress: <b>{getActiveJobProgress()}</b>
                    </p>
                    <DataGrid
                        rows={getRows(recalculationJobStatuses ? recalculationJobStatuses.jobs : [])}
                        columns={columns}
                        pageSize={10}
                        autoHeight
                        disableSelectionOnClick={true}
                        loading={loadingRecalculationJobs}
                        sortModel={[{field: 'createdAt', sort: 'desc' as GridSortDirection}]}
                    />
                </Fragment>
            )}
        </div>
    );
}
