import {useMemo} from "react";
import {QuestionWithMetrics} from "../types/QuestionWithMetrics";
import {parseQuestionWithMetrics} from "./utils";
import {useApiCall} from "./useApiCall";

interface UseQuestionReturn {
    questionWithMetrics?: QuestionWithMetrics | null,
    loading: boolean,
    error: string | null,
    refetch: () => void
}

export function useQuestion(questionId: string): UseQuestionReturn {
    const url = `/api/questions/${questionId}`;
    const {data, error, loading, refetch} = useApiCall(url);

    const questionWithMetrics = useMemo(() => {
        if (data) {
            return parseQuestionWithMetrics(data);
        }
        return null;
    }, [data]);

    return {
        questionWithMetrics,
        loading,
        error,
        refetch
    }
}
