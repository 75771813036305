import React from 'react';
import {ParameterUpdate} from "./components/ParameterUpdate";
import {CurrentParameters} from "./components/CurrentParameters";
import styled from "styled-components";
import {NavLink, Redirect, Route, Switch} from "react-router-dom";
import {LINKS} from "../../links";

export const Parameters = () => (
    <Wrapper>
        <TabsWrapper>
            <Tab to={LINKS.currentParameters()}>
                Current parameters
            </Tab>
            <Tab to={LINKS.parameterUpdate()}>
                Parameter updates
            </Tab>
        </TabsWrapper>
        <PageWrapper>
            <Switch>
                <Route path={LINKS.currentParameters()}>
                    <CurrentParameters/>
                </Route>
                <Route path={LINKS.parameterUpdate()}>
                    <ParameterUpdate/>
                </Route>
                <Route path="">
                    <Redirect to={LINKS.currentParameters()}/>
                </Route>
            </Switch>
        </PageWrapper>
    </Wrapper>
);

const Wrapper = styled.div`
  flex: 1;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
`;

const TabsWrapper = styled.div`
  flex: 0 0;
  margin-bottom: 40px;
`;

const Tab = styled(NavLink)`
  width: 200px;
  padding: 25px;
  color: #444;
  display: inline-block;
  margin-left: 0;
  padding: 10px;
  border-bottom: 2px solid #eee;
  transition: all .5s;
  font-weight: 500;
  cursor: pointer;
  color: #aaa;
  &.active {
    border-bottom: 2px solid #337ab7;
    color: #444;
  }
  text-decoration: none;
`;

const PageWrapper = styled.div`
  flex: 1 1;
  overflow-y: auto;
`;
