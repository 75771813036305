import {useCallback, useMemo} from "react";
import {parseDataCollectionStudy} from "./utils";
import {useApiOperation} from "./useApiOperation";
import {DataCollectionStudy} from "../types/DataCollectionStudy";


type UseUpdateDataCollectionStudyReturn = [(studyId: string) => void, {
    results?: DataCollectionStudy | null,
    loading: boolean;
    error?: string | null;
}]

export function useUpdateDataCollectionStudy(): UseUpdateDataCollectionStudyReturn {
    const url = '/api/dataCollection.updateStudy';
    const [request, {error, loading, data}] = useApiOperation(url);

    const updateDataCollectionStudy = useCallback((studyId: string) => {
        const payload = {'study_id': studyId};
        return request(payload);
    }, [request]);

    const results = useMemo(() => {
        return data ? parseDataCollectionStudy(data) : null;
    }, [data]);

    return [updateDataCollectionStudy, {
        results,
        loading,
        error
    }]
}
