import {useCallback, useMemo} from "react";
import {useApiOperation} from "./useApiOperation";


interface CreateRecalculationJobStatus {
    recalculationJobId: string | null;
    loading: boolean;
    error: string | null;
}

type CreateRecalculationJobReturn = [(after: string | Date, before: string | Date, recalculatedBefore: string | Date) => Promise<void>, CreateRecalculationJobStatus];

export function useCreateRecalculationJob(): CreateRecalculationJobReturn {
    const url = '/api/recalculationJobs.create';
    const [request, {data, error, loading}] = useApiOperation(url);

    const recalculationJobId = useMemo(() => {
        return data && data.recalculationJobId ? data.recalculationJobId : null;
    }, [data]);

    const makeRequest = useCallback((after: string | Date, before: string | Date, recalculatedBefore: string | Date) => {
        const payload = {
            after,
            before,
            recalculatedBefore
        };
        return request(payload);
    }, [request]);

    return [makeRequest, {
        recalculationJobId,
        loading,
        error
    } as CreateRecalculationJobStatus]
}
