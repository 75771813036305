import {useMemo} from "react";
import {useApiCall} from "./useApiCall";

interface UseGetDataCollectionStudyParticipantsReturn {
    dataCollectionStudyParticipants: string[];
    loading: boolean;
    error: string | null;
    refetch: () => void;
}

export function useGetDataCollectionStudyParticipants(studyId: string): UseGetDataCollectionStudyParticipantsReturn {
    const url = '/api/dataCollection.getStudyParticipants';
    const {data, error, loading, refetch} = useApiCall(url, JSON.stringify({'study_id': studyId}));
    const dataCollectionStudyParticipants = useMemo(() => {
        if (data) {
            return data.participants;
        }
        return [];
    }, [data]);

    return {
        dataCollectionStudyParticipants,
        loading,
        error,
        refetch
    }
}
