import {useMemo} from "react";
import {QuestionInfo} from "../types/QuestionInfo";
import {parseQuestions} from "./utils";
import {useApiCall} from "./useApiCall";

interface UseQuestionsReturn {
    questions: QuestionInfo[];
    loading: boolean;
    error: string | null;
}

export function useQuestions(): UseQuestionsReturn {
    const url = '/api/questions.list';
    const {data, error, loading} = useApiCall(url);
    const questions = useMemo(() => {
        if (data) {
            return parseQuestions(data);
        }
        return [];
    }, [data]);

    return {
        questions: questions,
        loading,
        error
    }
}
