import {ReactNode, useEffect, useState} from 'react'
import {parseUrl} from "../../config";
import {buildRequestHeaders, clearToken, getToken} from "./helpers";
import {Spinner} from "../../components/Spinner";
import {useHistory} from "react-router-dom";

type WithRequireAuthenticationProps = {
    children: ReactNode
}

export default function WithRequireAuthentication(props: WithRequireAuthenticationProps) {
    const history = useHistory();
    let [isVerified, setIsVerified] = useState(false);
    let [isLoading, setIsLoading] = useState(true);
    const accessToken = getToken();
    const goToLogin = () => {
        history.push('/login');
    };
    useEffect(() => {
        fetch(
            parseUrl(`/api/authorize`),
            {
                method: "POST",
                headers: buildRequestHeaders()
            }
        )
            .then(async res => {
                const response = await res.json();
                setIsLoading(false);
                if (response.data.ok) {
                    setIsVerified(true);
                } else {
                    clearToken();
                }
            })
            .catch(error => {
                setIsLoading(false);
                console.log(error);
            });
    }, [accessToken]);
    return (
        <>
            {isLoading ? <Spinner/> : null}
            {!isVerified && !isLoading ?
                <p>You are not logged in, go to <button onClick={goToLogin}>Login</button> page</p> : null}
            {isVerified && !isLoading ? props.children : null}
        </>
    )
}
