

export const LINKS = {
    login: () => '/login',
    logout: () => '/logout',
    start: () => '/start',
    parameters: () => '/parameters',
    currentParameters: () => '/parameters/current-parameters',
    parameterUpdate: () => '/parameters/parameter-update',
    recalculation: () => '/recalculation',
    newRecalculationJob: () => `/recalculation/new`,
    questions: () => '/questions',
    question: (questionId = ':questionId') => `/questions/${questionId}`,
    dataCollection: (studyId = '') => `/data-collection/${studyId}`,
    uploadItems: () => '/upload-items',
};
