import {useCallback, useMemo} from "react";
import {parseRecalculationJob} from "./utils";
import {RecalculationJob} from "../types/RecalculationJob";
import {useApiOperation} from "./useApiOperation";


type UseStopRecalculationJobReturn = [(recalculationJobId: string) => void, {
    results?: RecalculationJob | null,
    loading: boolean;
    error?: string | null;
}]

export function useStopRecalculationJob(): UseStopRecalculationJobReturn {
    const url = '/api/recalculationJobs.stop';
    const [request, {error, loading, data}] = useApiOperation(url);

    const stopRecalculationJob = useCallback((recalculationJobId: string) => {
        const payload = {
            recalculationJobId
        };
        return request(payload);
    }, [request]);

    const results = useMemo(() => {
        return data ? parseRecalculationJob(data) : null;
    }, [data]);

    return [stopRecalculationJob, {
        results,
        loading,
        error
    }]
}
